import React from 'react';
import Head from '../components/head';
import Layout from '../components/layout';

const PrivacyPolicy = () => {
  const PAGE = "Privacy Policy";

  return (
    <Layout>
      <Head title={PAGE}
        metaname="robots"
        contentValue="noindex" />

      <div className="policy-backdrop hero light">
        <div className="content">
          <p>It is Vintage Six Media, LLC policy to respect your privacy regarding
          any information we may collect from you or which you may provide to us,
          in the circumstances set out below. Accordingly, we have developed this
          privacy policy in order for you to understand how we collect, use,
          communicate, disclose, safeguard and otherwise make use of your personally
          identifiable information (“Personal Data”).</p>

          <h4>We will only collect and use your Personal Data where:</h4>

          <ul>
            <li>we have lawful grounds to do so, including to comply with our legal obligations</li>
            <li>we are performing a contract with you for our services and</li>
            <li>we have legitimate interests in using your Personal Data
            and your interests and fundamental rights do not override those interests.</li>
          </ul>

          <p>For the purposes of the EU General Data Protection Regulation
          ((EU) 2016/679) (“GDPR”), we, Vintage Six Media, LLC, are the “data controller”.
          If you have any questions about this policy or about how we use your Personal Data,
          please contact us via our contact details at the end of this policy.</p>

          <p>This policy is effective as of March 1, 2021. We review our privacy practices on an
          ongoing basis, and as such we may change this privacy policy from time to time.
          Please check this page frequently to ensure you are familiar with its current content.</p>

          <h4>1. Personal Data we collect via our website authorseanallen.com (the “Website”)</h4>
          <p><em>Personal Data that you provide to us</em></p>
          <p>We only collect Personal Data where you choose to interact with us on the Website in the following ways:</p>

          <table>
            <tbody>
              <tr className="odd">
                <td>
                  <h4>Activity</h4>
                </td>
                <td>
                  <h4>Types of Personal Data Collected</h4>
                </td>
              </tr>
              <tr className="even">
                <td>When you sign-up to:
                  <p>&#8226; receive free content, including eBooks, news and offers</p>
                </td>
                <td width="293">
                  <p>&#8226; Email address</p>
                  <p>&#8226; First name</p>
                  <p>&#8226; Last name</p>
                </td>
              </tr>
              <tr className="odd">
                <td>When you purchase a book directly from us</td>
                <td>&#8226; Name
                  <p>&#8226; Email address</p>
                  <p>&#8226; Phone number</p>
                  <p>&#8226; Any Personal Data which you provide to us in the free text box of the form</p>
                  <p>&#8226; Payment method choice (payment details are held by our payment processor, not us)</p>
                </td>
              </tr>
              <tr className="even">
                <td>When you complete the media enquiries form</td>
                <td>&#8226; Name
                  <p>&#8226; Email address</p>
                  <p>&#8226; Phone number</p>
                  <p>&#8226; Any Personal Data which you provide to us in the free text box of the form</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p><em>Data that we collect automatically </em></p>
          <p>We automatically collect certain information when you visit our
          Website – such as the type of browser and operating system you are
          using, and the domain name of your Internet service provider. We do
          not link this information with any Personal Data.</p>

          <p>We may also collect data automatically through our use of cookies on the Website.
          Please see our <a className="yellow-link" href="https://authorseanallen.com/cookies-policy/">cookie policy</a> to
          find out about the types of cookies we set and why, and how you can adjust your settings
          to disable cookies.</p>

          <h4>2. Use of your Personal Data</h4>

          <p>We will only use your Personal Data when the law allows us to.
          Most commonly, we use your Personal Data to:</p>

          <p>&ndash; process and manage your use of our website</p>

          <p>&ndash; respond to your questions, comments and requests</p>

          <p>&ndash; where you have opted-in to receive marketing from us,
          deliver communications that are relevant to your
            preferences / may be of interest to you</p>

          <p>&ndash; improve our services and Website through analysis of information.</p>

          <h4>3. Sharing of your Personal Data </h4>
          <p>We take your privacy seriously and will not share your Personal
            Data with others, except as permitted by applicable law or as set out below:</p>

          <p>We share Personal Data as necessary with third parties
          who provide services or functions on our behalf and who
          require the information to provide those specific services to us.
          These third parties may include social media advertising platforms
          such as Facebook and Google Adwords for the purpose of custom audience
          generation and the development of targeting criteria for other audiences.
          Please note that we have appropriate data privacy safeguards in
          place with third parties with whom we share Personal Data as described
            above and who are providing services or functions on our behalf.</p>

          <h4>4. Keeping your Personal Data secure</h4>

          <p>We have implemented security policies and technical
          measures to safeguard the Personal Data we collect.
          We maintain physical, electronic and procedural
          safeguards that comply with applicable law, including the GDPR,
          to safeguard Personal Data from accidental loss, destruction or
            damage and unauthorised access, use and disclosure.</p>

          <h4>5. Retention periods for use of your Personal Data</h4>

          <p>We will use and store your Personal Data only for as long as necessary,
          bearing in mind the uses of your Personal Data as described in this
          privacy policy and otherwise as communicated to you. We review the Personal
          Data we hold at regular intervals and delete permanently or anonymise any
            Personal Data which is no longer necessary.</p>

          <h4>6. Children</h4>

          <p>This Website and our services are aimed at adults,
          and we do not knowingly collect any Personal Data
          relating to children aged under 18 years old. If
          you are under the age of 18, please do not provide
          us with any of your Personal Data, including your
            email address.</p>

          <h4>7. Access to and control over your Personal Data </h4>

          <p>You have legal rights under applicable law in relation to your
          Personal Data. You can ask the following questions, or take the
          following actions, at any time by contacting us via email <a className="yellow-link" href="mailto:sean@authorseanallen.com">sean@authorseanallen.com</a> or via our postal address:</p>

          <p>&ndash; see what Personal Data we hold about you (if any),
          including why we are holding it and who it
            could be disclosed to</p>

          <p>&ndash; ask us to change/correct your Personal Data</p>

          <p>&ndash; ask us to delete your Personal Data</p>

          <p>&ndash; object to the processing of your Personal Data</p>

          <p>&ndash; ask us to restrict the processing of your Personal Data</p>

          <p>&ndash; withdraw any consents you have given us to the processing of your Personal Data and</p>

          <p>&ndash; express any concerns you have about third parties’ use of your Personal Data.</p>

          <h4>8. Change of purpose</h4>

          <p>We will only use your Personal Data for the purposes for which
          we collected it, unless we reasonably consider that we need to
          use it for another reason and that reason is compatible with
          the original purpose. If you wish to have an explanation as
          to how the processing for the new purpose is compatible with
            the original purpose, please contact us.</p>

          <p>If we need to use your Personal Data for an unrelated purpose,
          we will notify you and we will explain the legal
            basis which allows us to do so.</p>

          <h4>9. Contact Us</h4>
          <ul className="postal">
            <li>Vintage Six Media, LLC</li>
            <li>2831 Leigh Avenue</li>
            <li>San Jose CA, 95124</li>
          </ul>
          <p>Email for Privacy Questions: <a className="yellow-link" href="mailto:privacy@vintagesixmedia.com">privacy@vintagesixmedia.com</a></p>
        </div>
      </div>
    </Layout>
  )

}

export default PrivacyPolicy;
