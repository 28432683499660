import React from 'react';
import PrivacyPolicy from '../components/privacyPolicy';


const Privacy = () => {
  return (
    <PrivacyPolicy />
  )
}

export default Privacy;