import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Nav from '../components/nav';

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }  `);


  const showHeader = props.showHeader;

  useEffect(() => {
    loadBGImage();
  });

  const loadBGImage = () => {
    const imageUrl = require('../../static/anglePattern.svg');

    let bgElement = document.querySelector(".header");

    if (!bgElement) return;

    bgElement.classList.add("bg-loading");
    let preloaderImg = document.createElement("img");
    preloaderImg.src = imageUrl;

    preloaderImg.addEventListener('load', (event) => {
      bgElement.classList.remove("bg-loading");
      bgElement.style.backgroundImage = `url(${imageUrl})`;
      preloaderImg = null;
    });
  }


  return (
    <React.Fragment>
      <header className={showHeader ? "header bg-loading" : "hide"}>
        <div className="author-name" >
          <span className="mobile first-name">Sean </span>
          <span className="mobile last-name">Allen</span>
        </div>
      </header>
      <Nav />
    </React.Fragment>
  )
}

export default Header;