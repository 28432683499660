import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import '../styles/index.scss';


const Layout = (props) => {
  return (
    <React.Fragment>
      <Header
        showHeader={props.showHeader}
        isCharacter={props.isCharacter}
      />
      <div className="guts">
        {props.children}
      </div>
      <Footer />
    </React.Fragment>

  )
}

export default Layout;